.workspace {
  padding: 20px 50px 50px;
}

.button-add {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  position: absolute;
  bottom: 5%;
  right: 5%;
}
