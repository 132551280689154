.root {
  display: flex;
  height: 100%;
}

.left-section {
  flex: 4;
  display: flex;
  justify-content: center;
  align-items: center;
}

.right-section {
  flex: 5;
  background-image: url("assets/images/bg.png");
  background-repeat: no-repeat;
  background-size: cover;
  justify-content: center;
  align-items: center;
}

.header {
  display: flex;
  position: absolute;
  top: 3%;
  left: 3%;
}

.logo-top {
  fill: #0d6efd;
  height: 30px;
  width: 30px;
  margin-right: 20px;
  justify-content: center;
  align-items: center;
}

.logoTitle {
  font-size: 20px;
  margin: 0;
  padding-top: 4px;
}

.logo {
  height: 45px;
  width: 45px;
  vertical-align: baseline;
}

.backgroundTitle {
  color: white;
  margin: 0;
  text-align: center;
  padding-top: 4px;
}

.background {
  color: white;
}
