.container {
  background-color: white;
  padding: 30px;
  box-shadow: 2px 1px 4px 1px rgba(0, 0, 0, 0.4);
}

.avartar {
  width: 40px;
  border: 1px solid black;
}
